<template>
    <div>
        <div class="case">
            <div v-if="type == null">
                <div class="h2" v-show="caseShow">相关案例</div>
                <div class="content" v-show="caseShow">如需体验案例，或查看更多功能欢迎点击导航联系我们</div>
            </div>
            <div v-else>
                <div class="h3" v-show="caseShow">开发案例</div>
                <ul  class="intelligen_ul" v-show="caseShow">
                    <li v-for="(item,index) in cases" :key="index" @click="caseSwitch(index)" :class="{'stateColor': isSelect == index}">{{item}}</li>
                </ul>
            </div>
                    <div class="case-swiper-container"  @mouseenter="case_enter" @mouseleave="case_leave" v-show="caseShow">
                        <div class="swiper-button-prev swiper-button-prev2 arrows left" ></div>
                        <swiper ref="caseSwiper" :options="windowWidth>1024?caseOptions:mobile_caseOptions">
                            <swiper-slide v-for="(item,index) in caseList" :key="index">
                                <div class="case_img">
                                    <div class="title">
                                        <p>{{item.title}}</p>
                                        <p>解决方案</p>
                                        <p>——</p>
                                    </div>
                                    <div class="content">
                                        <p>{{item.content1}}</p>
                                        <p>{{item.content2}}</p>
                                    </div>
                                    <div class="phone">
                                        <img :src="item.img" alt="">
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-button-next swiper-button-next2 arrows right" ></div>
                    </div>
                
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
    components:{Swiper,SwiperSlide},
    props:{
        type:{
            type:Number,
            default:null
        },
        caseList:{
            type:Array,
            default:()=>{}
        },
        caseShow:{
            type:Boolean,
            default:false
        },
    },
    data(){
        return{
            caseOptions: {
                pagination: {
                    el: ".case-swiper-pagination"
                    //区分不同Swiper的组件
                },
                loop: true,
                //开启循环
                loopedSlides :6,
                //设置平滑
                slidesPerView : 3,
                //切换速度
                speed: 1000,
                //左右箭头按钮
                navigation: {
                    prevEl: ".swiper-button-prev2",
                    nextEl: ".swiper-button-next2"
                },
                //不需要自动轮播时
                // autoplay: false,
                // 设置自动轮播的延迟时间
                autoplay: {
                    delay: 1000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                paginationAsRange : true,
                
                loopAdditionalSlides : 1,
            },
            mobile_caseOptions: {
                pagination: {
                el: ".case-swiper-pagination"
                },
                loop: true,
                loopedSlides :6,
                slidesPerView : 2,
                //切换速度
                speed: 1000,
                //左右箭头按钮
                navigation: {
                    prevEl: ".swiper-button-prev2",
                    nextEl: ".swiper-button-next2"
                },
                // effect: 'coverflow',
                // coverflowEffect: {
                //     rotate: 0,
                //     stretch:-10,
                //     depth: 0,
                //     modifier: 1,
                //     slideShadows: false
                // },
                //不需要自动轮播时
                // autoplay: false,
                // 设置自动轮播的延迟时间
                autoplay: {
                    delay: 1000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },

            },
            cases:['营销策划','旅游','电商','酒店管理','美容'],
            windowWidth: document.documentElement.clientWidth, // 实时屏幕宽度
            isSelect:0
        }
    },
    mounted(){
        this.caseSwiper.slideTo(1, 1000, false);
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
            })()
        }
    },
     /* 引入组件 */
    computed: {
        caseSwiper(){
            return this.$refs.caseSwiper.$swiper
        }
    },
    methods:{
        case_enter() {
            this.caseSwiper.autoplay.stop()
        },
        case_leave() {
            this.caseSwiper.autoplay.start()
        },
        caseSwitch(index){
            this.isSelect = index
            this.$emit('caseSwitch',index)
        }
    }
}
</script>

<style lang="less" scoped>
    .case{
        width: 100%;
        background: #f8f8f8;
        height: 630px;
        .h2{
            padding: 80px 0 20px 0;
        }
        .content{
            margin-bottom: 20px;
        }
        .h3 {
            font-weight: 700;
            font-size: 36px;
            color: #000;
            padding: 80px 0 20px 0;
        }
        .intelligen_ul {
            display: flex;
            text-align: center;
            justify-content: center;
            // padding-left: 605px;
            margin-bottom: 50px;
            li {
                width: 126px;
                color:#666666;
                font-size: 18px;
                height: 36px;
                line-height: 36px;
                border: 1px solid #666666;
                border-radius: 5px;
                margin: 0 8px;
                cursor: pointer;
                &:hover {
                    background-color: #078afa;
                    color:#fff;
                    border: 1px solid #078afa;
                }
            }
        }
        .case-swiper-container{
            width: 1050px;
            margin: 0 auto;
            position: relative;
            .swiper-slide{
                .case_img{
                    width: 340px;
                    height: 320px;
                    background: url('~@/assets/image/caseSetting.png') no-repeat;
                    background-size: 340px 320px;
                    position: relative;
                    cursor: pointer;
                    .title{
                        position: absolute;
                        top: 40px;
                        left: 20px;
                        font-size: 20px;   
                        color: #FFFFFF;
                        line-height: 27px;
                        text-align: left;
                    }
                    .content{
                        width: 80%;
                        position: absolute;
                        top: 75%;
                        left: 50%;
                        transform: translateX(-50%);
                        p:nth-child(1){
                            font-size: 16px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #333333;
                            
                        }
                        p:nth-child(2){
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 300;
                            color: #666666;
                        }
                    }
                    .phone{
                        position: absolute;
                        right: 30px;
                        top: 12px;
                        img{
                            width: 145px;
                        }
                    }
                }
            }
            .arrows{
                position: absolute;
                width: 65px;
                height: 65px;
                border-radius: 50%;
                border: 3px solid #9f9f9f;
                transform: translateY(-130%);
                cursor: pointer;
                outline: none;
            }
            .left{
                top: 75%;
                left: -100px;
                background: url('~@/assets/image/enterprise/left.png') no-repeat 12px 13px;
                background-size: 50% 50%;
                &:hover{
                    background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 12px 13px;
                    border: 3px solid #078afa;
                    background-size: 50% 50%;

                }
            } 
            .right{
                top: 75%;
                right: -100px;
                background: url('~@/assets/image/enterprise/right.png') no-repeat 16px 14px;
                background-size: 50% 50%;
                &:hover{
                    background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 16px 14px;
                    border: 3px solid #078afa;
                    background-size: 50% 50%;
                }
            }
        }
        .stateColor{
            background-color: #078afa !important;
            color:#fff !important;
            border: 1px solid #078afa !important;
        }
    }
    
    .swiper-button-prev::after{
        content: "" !important;
    }
    .swiper-button-next::after{
        content: "" !important;
    }
    @media screen and (max-width: 1024px) {
        .case{
            width: 100%;
            height: 255px;
            .h2{
                line-height: inherit;
                padding: 25px 0 8px;
            }
            .content{
                margin-bottom: 15px;
            }
            .h3 {
                padding: 25px 0 8px;
                font-size: 19px;
            }
            .intelligen_ul {
                margin-bottom: 15px;
                li {
                    width: 58px;
                    height: 19px;
                    line-height: 19px;
                    font-size: 10px;
                    margin: 0 5px;
                    
                }
            }
            .case-swiper-container{
                width: 275px;
                .swiper-slide{
                    .case_img{
                        width: 138px;
                        height: 130px;
                        background-size: 138px 130px;
                        .title{
                            top: 15px;
                            left: 2px;
                            line-height: 14px;
                            font-size: 10px;
                            transform: scale(0.84)
                        }
                        .content{
                            width: 216px;
                            top: 70%;
                            p:nth-child(1){
                                font-size: 8px;
                                transform: scale(0.67);
                            }
                            p:nth-child(2){
                                margin-top: -10px;
                                font-size: 6px;
                                transform: scale(0.5);
                            }
                        }
                        .phone{
                            top: 10px;
                            right: 12px;
                            img{
                                width: 47px;
                                height: 72px;
                            }
                        }
                    }
                }
                .arrows{
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    border: 3px solid #9f9f9f;
                    transform: translateY(-130%);
                    cursor: pointer;
                }
                .left{
                    top: 84%;
                    left: -38px;
                    background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 4px 5px;
                    border: 3px solid #078afa;
                    background-size: 50% 50%;
                    &:hover{
                        background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 4px 5px;
                        background-size: 50% 50%;
                    }
                } 
                .right{
                    top: 84%;
                    right: -38px;
                    background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 5px 5px;
                    border: 3px solid #078afa;
                    background-size: 50% 50%;
                    &:hover{
                        background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 5px 5px;
                        background-size: 50% 50%;
                    }
                }
            }
        }
    }
    
</style>